.container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
}

.page_button {
    background-color: var(--white);
    color: var(--primary);
    padding: 5px 10px;
    border: 1px solid var(--gray-light);
    border-radius: 5px;
    font-size: 1.2em;
}

.page_button:hover {
    color: var(--white);
    background-color: var(--primary);
    transition: color 0.2s ease;
}

.page_button:disabled {
    cursor: default;
}

.page_button.active {
    background-color: var(--primary);
    color: var(--white);
}