.form_container {
    margin: 35px auto;
    padding: 10px 20px;
    border: 1px solid var(--gray-light);
    ;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.form_body {
    display: flex;
    flex-direction: column;
}

.navlink {
    color: var(--primary);
    font-size: 18px;
    text-decoration: none;
    padding: 10px 0;
}

.navlink:hover {
    color: var(--primary-hover);
    transition: color 0.2s ease;
}

.error_panel {
    border: 1px solid var(--gray-light);
    border-radius: 4px;
    background-color: var(--red);
    padding: 10px;
    margin: 10px 0;
    color: var(--white);
}