.form_body {
    display: flex;
    flex-direction: column;
    max-width: 540px;
    justify-self: end;
}


.form_label {
    font-size: 1rem;
    font-family: var(--font-family);
}

.panel {
    border: 1px solid var(--gray-light);
    border-radius: 4px;
    background-color: var(--red);
    padding: 10px;
    margin: 10px 0 ;
    color: var(--white);
}

.success { 
    background-color: var(--green);
}

.error { 
    background-color: var(--red);
}