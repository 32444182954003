.card {
  border: 1px solid var(--gray-light);
  padding: 15px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 15px;
  min-width: 320px;
}

.container {
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
}

.container.sub {
  flex-grow: 1;
}


.status_group {
  display: flex;
  justify-content: space-between;

  gap: 10px;
}

.author,
.email,
.text {
  font-size: 1.2em;
  font-family: var(--font-family);
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  text-wrap: stable;
}

.email,
.text {
  margin-top: 10px;
}

.status {
  font-size: 0.9em;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: var(--gray);
  color: var(--white);
  text-wrap: nowrap;
}

.action_group {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 10px;
}

.edit_button,
.done_button {
  text-wrap: nowrap;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 1em;
}