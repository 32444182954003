.layout {
    box-sizing: border-box;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.container {
    width: 100%;
    max-width: 575px;
    margin: 0 auto;
    padding: 0 25px;
}

.header_background {
    min-width: 100%;
    padding: 15px 0;
    background: var(--black);
}

.header {
    display: flex;
    justify-content: space-between;
}

.navlink {
    color: var(--white);
    font-size: 1.2em;
    text-decoration: none;
    padding: 10px 0;
}

.navlink:hover {
    color: var(--gray-light);
    transition: color 0.2s ease;
}

.header_background,
.footer {
    flex-grow: 0;
    flex-shrink: 0;
}

.footer {
    font-size: 22px;
    padding: 25px 25px;
}

.main {
    flex-grow: 1;
}